
export default {
  name: 'AgeGate',
  minAge: {
    type: Number,
    default: 18,
  },
  data() {
    return {
      valid: false,
      year: '',
      month: '',
      day: '',
      remember: false,
      scrolled: false,
    };
  },
  computed: {
    years() {
      const currYear = new Date().getUTCFullYear();
      return Array.from({ length: 120 }, (x, i) => currYear - i).map((y) => ({ text: y, value: y }));
    },
    months() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((mon) => {
        return { text: new Date(2000, mon).toLocaleString({}, { month: 'long' }), value: mon + 1 };
      });
    },
    days() {
      const daysInMonth = new Date(this.year, this.month, 0).getDate();
      return Array.from({ length: daysInMonth }, (_, i) => i + 1).map((y) => ({ text: y, value: y }));
    },
  },
  methods: {
    scrollToDate(ref) {
      !this.scrolled && setTimeout(() => {
        const content = ref.$refs?.menu?.$refs.content;
        if (content) {
          content.scrollTop = 600;
          setTimeout(() => {
            content.scrollTo({ top: 1200, behavior: 'smooth' });
            this.scrolled = true;
          }, 50);
        }
      }, 200);
    },
    required(v) {
      return (Array.isArray(v) ? v.length > 0 : !!v) || this.$t('errors.required');
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit('auth/birthdate', {
          birthdate: this.year + '/' + this.month.toString().padStart(2, '0') + '/' + this.day.toString().padStart(2, '0'),
          remember: this.remember,
        });
      }
    },
  },
};

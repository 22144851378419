const promoComp = require('rewart-frontend-library/src/components/promoComponent');
const maybeReload = require('rewart-frontend-library/src/utils/maybeReload');

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => maybeReload(import(/* webpackChunkName: "home" */ './views/Home')),
  },
  {
    path: '/profilom',
    name: 'profile',
    meta: {
      auth: true,
      title: 'Adatmódosítás',
    },
    component: () => maybeReload(import(/* webpackChunkName: "profile" */ './views/Profile')),
    children: [
      {
        path: 'entry/:entry_id/address',
        name: 'entry-address',
        meta: {
          title: 'Szállítási adatok',
        },
        component: () => maybeReload(import(/* webpackChunkName: "profile" */ './views/Address')),
      },
      {
        path: 'nyertesek/:promotion',
        name: 'winners-modal',
        meta: {
          title: 'Nyertesek',
        },
        component: () => maybeReload(import(/* webpackChunkName: "profile" */ './views/Profile/WinnersModal')),
      },
    ],
  },
  {
    path: '/recover',
    name: 'recover',
    meta: {
      auth: false,
      age: false,
      title: 'Elfelejtett jelszó',
    },
    component: () => maybeReload(import(/* webpackChunkName: "recover" */ './views/Login/RecoverPassword')),
  },
  {
    path: '/reset',
    name: 'reset',
    meta: {
      auth: false,
      age: false,
      index: false,
      title: 'Elfelejtett jelszó',
    },
    component: () => maybeReload(import(/* webpackChunkName: "reset" */ './views/Login/ResetPassword')),
  },
  {
    path: '/regisztracio',
    name: 'register',
    meta: {
      auth: false,
      title: 'Regisztráció',
    },
    component: () => maybeReload(import(/* webpackChunkName: "register" */ './views/Register')),
  },
  {
    path: '/belepes',
    name: 'login',
    meta: {
      auth: false,
      age: false,
      title: 'Belépés',
    },
    component: () => maybeReload(import(/* webpackChunkName: "login" */ './views/Login')),
  },
  {
    path: '/regisztracio-allapota',
    name: 'registration-status',
    meta: {
      age: false,
      index: false,
    },
    component: () => maybeReload(import(/* webpackChunkName: "registration-status" */ './views/Register/Status')),
  },
  {
    path: '/marketing/elhagyas',
    name: 'marketing-leave',
    meta: {
      age: false,
      title: 'Érvényesített fiók törlése',
    },
    component: () => maybeReload(import(/* webpackChunkName: "marketing-leave" */ './views/Marketing')),
  },
  {
    path: '/age-gate',
    name: 'age-gate',
    redirect: {name: 'home'},
  },
  {
    path: '/p/:promotion*',
    redirect(from) {
      return from.fullPath.replace('/p/', '/promocio/');
    },
  },
  {
    path: '/code/:code_id/reupload',
    meta: {
      auth: true,
    },
    component: {
      async beforeRouteEnter(to, from, next) {
        const {default: Code} = await import('rewart-frontend-library/src/models/Code');

        await Code.load(null, to.params.code_id);
        const code = Code.query().find(to.params.code_id);
        if (!code) {
          next({name: '404', params: {0: to.fullPath}});
        } else {
          next({...to, name: 'code-reupload', params: {...to.params, promotion: code.promotion_id}});
        }
      },
    },
  },
  {
    path: '/promocio/:promotion',
    component: () => maybeReload(import('./components/PromoPage')),
    children: [
      {
        path: '',
        name: 'promotion',
        component: promoComp((p) => import(/* webpackChunkName: "promotions-[request]" */ './views/Promotions/' + p + '/Home')),
      },
      {
        path: 'code/:code_id/reupload',
        name: 'code-reupload',
        meta: {
          auth: true,
        },
        component: promoComp((p) => import(/* webpackChunkName: "promotions-[request]" */ './views/Promotions/' + p + '/CodeUpload/Reupload')),
      },
      {
        path: 'code/:code_id/result',
        name: 'code-upload-result',
        meta: {
          auth: true,
        },
        component: promoComp((p) => import(/* webpackChunkName: "promotions-[request]" */ './views/Promotions/' + p + '/CodeUpload/Result')),
        children: [
          {
            path: 'entry/:entry_id/address',
            name: 'code-entry-address',
            component: () => maybeReload(import(/* webpackChunkName: "profile" */ './views/Address')),
          },
        ],
      },
    ],
  },
  {
    path: '/media/:file',
    beforeEnter(to, from, next) {
      try {
        window.location.href = require('@media/' + to.params.file);
      } catch (e) {
        next({name: '404', params: {0: to.fullPath}});
      }
    },
  },
  {
    path: '*',
    name: '404',
    meta: {
      age: false,
    },
    component: () => maybeReload(import(/* webpackChunkName: "not-found" */ './views/NotFound')),
  },
];

module.exports = routes;

import LoadableModel from 'rewart-frontend-library/src/models/LoadableModel';
import axios from 'rewart-frontend-library/src/plugins/axios';

export default class Coupon extends LoadableModel {
  static entity = 'coupon';

  static fields() {
    return {
      id: this.attr(null),
      promotion_id: this.attr(null),

      slug: this.attr(''),

      unlocked: this.boolean(false),
      code: this.attr(null),
    };
  }

  static async loader(page, promo_id) {
    return await axios.get('coupon/get/' + promo_id);
  }

  async redeem() {
    const { coupon } = (await axios.post('coupon/use-code', { id: this.id })).data;
    await Coupon.insertOrUpdate({
      data: coupon,
    });

    return Coupon.query().whereId(coupon.id).first();
  }
}

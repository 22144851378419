import LoadableModel from 'rewart-frontend-library/src/models/LoadableModel';
import axios from 'rewart-frontend-library/src/plugins/axios';

export default class ExtraCan extends LoadableModel {
  static entity = 'extra-can';

  static fields() {
    return {
      id: this.attr(null),
      promotion_id: this.attr(null),
      slug: this.attr(''),
      name: this.attr(''),
      unlocked: this.attr(false),
    };
  }

  static async loader(page, promo_id) {
    return await axios.get('cans/get/' + promo_id);
  }

  async upload(file) {
    const form = new FormData();
    form.set('file', file);
    const can = (await axios.post('cans/'+this.promotion_id+'/upload/'+this.slug, form)).data;
    await ExtraCan.insertOrUpdate({
      data: can,
    });
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/szurkoljesnyerj/age-gate-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Automatically generated, do not edit */
/* Automatically generated, do not edit */
.age-gate {
  padding: 45px 0;
  background-color: #003D3D;
}
@media only screen and (min-width: 768px) {
.age-gate {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
}
.age-gate form {
  display: inline-block;
  max-width: 700px;
  margin-bottom: 40px;
}
.age-gate .page-title {
  color: #fff;
  margin-bottom: 30px;
  font-size: 34px;
  line-height: 1.2em;
  text-transform: uppercase;
  position: relative;
}
@media only screen and (max-width: 767.98px) {
.age-gate .page-title {
    font-size: 28px;
}
}
.age-gate h2 {
  color: #FFF;
  font-size: 34px;
  font-weight: bold;
  line-height: normal;
  text-transform: uppercase;
}
@media only screen and (max-width: 767.98px) {
.age-gate h2 {
    font-size: 28px;
}
}
.age-gate h2 span {
  color: #DCA167;
  font-style: italic;
}
.age-gate h3 {
  color: #DCA167;
  font-size: 20px;
}
.age-gate p {
  font-size: 20px;
  line-height: 1.2em;
  color: #fff;
}
.age-gate a {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/views/AgeGate/index.vue"],"names":[],"mappings":"AAAA,yCAAyC;AACzC,yCAAyC;AACzC;EACE,eAAe;EACf,yBAAyB;AAC3B;AACA;AACE;IACE,yDAAiE;IACjE,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AACxB;AACF;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;AACpB;AACA;AACE;IACE,eAAe;AACjB;AACF;AACA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;AACE;IACE,eAAe;AACjB;AACF;AACA;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":["/* Automatically generated, do not edit */\n/* Automatically generated, do not edit */\n.age-gate {\n  padding: 45px 0;\n  background-color: #003D3D;\n}\n@media only screen and (min-width: 768px) {\n  .age-gate {\n    background-image: url(\"@/assets/szurkoljesnyerj/age-gate-bg.jpg\");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n  }\n}\n.age-gate form {\n  display: inline-block;\n  max-width: 700px;\n  margin-bottom: 40px;\n}\n.age-gate .page-title {\n  color: #fff;\n  margin-bottom: 30px;\n  font-size: 34px;\n  line-height: 1.2em;\n  text-transform: uppercase;\n  position: relative;\n}\n@media only screen and (max-width: 767.98px) {\n  .age-gate .page-title {\n    font-size: 28px;\n  }\n}\n.age-gate h2 {\n  color: #FFF;\n  font-size: 34px;\n  font-weight: bold;\n  line-height: normal;\n  text-transform: uppercase;\n}\n@media only screen and (max-width: 767.98px) {\n  .age-gate h2 {\n    font-size: 28px;\n  }\n}\n.age-gate h2 span {\n  color: #DCA167;\n  font-style: italic;\n}\n.age-gate h3 {\n  color: #DCA167;\n  font-size: 20px;\n}\n.age-gate p {\n  font-size: 20px;\n  line-height: 1.2em;\n  color: #fff;\n}\n.age-gate a {\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

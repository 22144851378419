// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Automatically generated, do not edit */
/* Automatically generated, do not edit */
.footer {
  position: relative !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.footer > .row {
  width: 100%;
}
.footer.theme--dark.v-sheet {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #0e2111;
  box-shadow: none !important;
}
.footer .v-btn {
  text-transform: none;
  letter-spacing: normal;
  font-size: 20px !important;
  font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.scss"],"names":[],"mappings":"AAAA,yCAAyC;AACzC,yCAAyC;AACzC;EACE,6BAA6B;EAC7B,6BAA6B;EAC7B,8BAA8B;AAChC;AACA;EACE,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,yBAAyB;EACzB,2BAA2B;AAC7B;AACA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,0BAA0B;EAC1B,mBAAmB;AACrB","sourcesContent":["/* Automatically generated, do not edit */\n/* Automatically generated, do not edit */\n.footer {\n  position: relative !important;\n  padding-left: 30px !important;\n  padding-right: 30px !important;\n}\n.footer > .row {\n  width: 100%;\n}\n.footer.theme--dark.v-sheet {\n  padding-top: 20px;\n  padding-bottom: 20px;\n  background-color: #0e2111;\n  box-shadow: none !important;\n}\n.footer .v-btn {\n  text-transform: none;\n  letter-spacing: normal;\n  font-size: 20px !important;\n  font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Automatically generated, do not edit */
.drink-responsibly {
  opacity: 0.5;
}
.drink-responsibly .images > * {
  margin: 0 15px;
  max-width: 32px;
}
@media only screen and (max-width: 767.98px) {
.drink-responsibly .images > * {
    margin: 0 5px;
}
}`, "",{"version":3,"sources":["webpack://./src/components/DrinkResponsibly.vue"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;EACE,YAAY;AACd;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;AACE;IACE,aAAa;AACf;AACF","sourcesContent":["/* Automatically generated, do not edit */\n.drink-responsibly {\n  opacity: 0.5;\n}\n.drink-responsibly .images > * {\n  margin: 0 15px;\n  max-width: 32px;\n}\n@media only screen and (max-width: 767.98px) {\n  .drink-responsibly .images > * {\n    margin: 0 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;


import HamburgerMenu from 'rewart-frontend-library/src/components/HamburgerMenu';
import UsesPromoPage from 'rewart-frontend-library/src/mixins/UsesPromoPage';
import { Promotion } from 'rewart-frontend-library/src/models';

import AppButton from './Button';

/**
 * This is the site header
 */
export default {
  name: 'AppHeader',
  components: { HamburgerMenu, AppButton },
  mixins: [UsesPromoPage],
  data() {
    return {
      showMobileNav: false,
      ready: window.__PRERENDER_INJECTED,
    };
  },
  computed: {
    currentPromotion() {
      return Promotion.getCurrent(this.promotionId, this.now);
    },

    currentPromotions() {
      return Promotion.getCurrents(this.now, 1);
    },
  },
  watch: {
    $route() {
      this.showMobileNav = false;
    },
  },
  created() {
    Promotion.load();
    this.$nextTick(() => this.ready = true);
  },
};

import 'rewart-frontend-library/src/registerServiceWorker';

import minViewport from '@tofandel/min-viewport';
import rewartLib from 'rewart-frontend-library/src/main';
import database from 'rewart-frontend-library/src/plugins/database';
import supportsWebp from 'rewart-frontend-library/src/utils/supportsWebp';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';

import App from './App.vue';
import * as Models from './models';
import i18n, {switchLocale} from './plugins/i18n';
import router from './plugins/router';
import store from './plugins/store';
import vuetify from './plugins/vuetify';


Vue.use(VueGtag, {
  bootstrap: !window.__PRERENDER_INJECTED,
  config: {id: process.env.VUE_APP_ANALYTICS},
}, router);

Vue.use(VueMeta);

Object.values(Models).forEach((model) => {
  database.register(model);
});

Vue.config.productionTip = false;

Vue.use(rewartLib, {
  vuetify,
  i18n,
  store,
  router,
});

minViewport(400);

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  data() {
    return {
      webp: true,
    };
  },
  created() {
    supportsWebp().catch(() => {
      this.webp = false;
      document.getElementsByTagName('html')[0].classList.add('nowebp');
    });
  },
  mounted() {
    if (!window.__PRERENDER_INJECTED) {
      const b = document.getElementsByTagName('script')[0];
      this.$gtag.query('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'wait_for_update': 500,
      });

      const t = document.createElement('script');
      t.async = !0;
      t.onload = () => {
        const __i__ = self.postMessage && setInterval(() => {
          if (self.PrivacyManagerAPI && __i__) {
            const apiObject = {
              PrivacyManagerAPI:
                {
                  action: 'getConsentDecision',
                  timestamp: new Date().getTime(), self: document.location.host,
                },
            };
            const json = JSON.stringify(apiObject);
            window.top.postMessage(json, '*');
            let lastDecision = null;
            window.addEventListener('message', ({data}) => {
              try {
                const msg = typeof data === 'string' ? JSON.parse(data) : null;
                if (msg?.PrivacyManagerAPI?.action === 'getConsentDecision') {

                  const decision = window.truste.cma.callApi('getGDPRConsentDecision', document.location.hostname).consentDecision;
                  if (JSON.stringify(lastDecision) !== JSON.stringify(decision)) {
                    lastDecision = decision;
                    this.$gtag.query('consent', 'update', {
                      'ad_storage': decision.includes(3) ? 'granted' : 'denied',
                      'analytics_storage': decision.includes(2) ? 'granted' : 'denied',
                    });
                  }
                }
                // eslint-disable-next-line no-empty
              } catch (e) {
              }
            }, false);
            clearInterval(__i__);
          }
        }, 50);
      };
      t.src = '//consent.trustarc.com/notice?domain=molsoncoors-eu.com&c=teconsent&js=nj&noticeType=bb&gtm=1';
      b.parentNode.insertBefore(t, b);

      const h = document.createElement('script');
      h.src = '//form-renderer.trustarc.com/browser/client.js';
      h.defer = true;
      b.parentNode.insertBefore(h, b);
      window.trustarc = window.trustarc || {};
      const r = window.trustarc;
      r.irm = [];

      for (
        let n = ['init', 'shouldShowGPCBanner', 'setGPCSubmitted', 'destroy'],
          i = 0;
        i < n.length;
        i++
      ) {
        const o = n[i];

        r.irm[o] = r.irm[o] || function (...args) {
          r.irm.push([o, args]);
        };
      }

      r.irm.init(
        {
          formId: '90aede09-1137-408f-a8fd-27008a27d3c4',
          gpcDetection: true,
        },
        (error) => {
          console.error('[IRM Form Error]', error);
        },
      );
    }
  },
  render: h => h(App),
});

switchLocale().then(() => vue.$mount('#app'));

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import ScssVars from '../variables.json';

import VueI18n from './i18n';

const { theme, 'grid-breakpoints': thresholds, ...colors } = ScssVars;

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: theme === 'dark',
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      [theme]: {
        ...colors,
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: thresholds.md,
    thresholds,
    scrollBarWidth: 24,
  },
  lang: {
    t: (key, ...params) => VueI18n.t(key, params),
    current: process.env.VUE_APP_I18N_LOCALE.slice(0, 2),
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});

/**
 * This is the site footer
 */
import UsesPromoPage from 'rewart-frontend-library/src/mixins/UsesPromoPage';

import DrinkResponsibly from '@/components/DrinkResponsibly';

export default {
  name: 'AppFooter',
  components: {DrinkResponsibly},
  mixins: [UsesPromoPage],
};

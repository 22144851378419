// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Automatically generated, do not edit */
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100%;
}
body, html {
  height: 100%;
  width: 100%;
}
#consent_blackbar {
  z-index: 99999999;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.lazy, .lazy * {
  background: none !important;
}`, "",{"version":3,"sources":["webpack://./src/App.vue"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;EACE,mCAAmC;EACnC,kCAAkC;EAClC,kBAAkB;EAClB,gBAAgB;AAClB;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,SAAS;AACX;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["/* Automatically generated, do not edit */\n#app {\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow-x: hidden;\n  min-height: 100%;\n}\n\nbody, html {\n  height: 100%;\n  width: 100%;\n}\n\n#consent_blackbar {\n  z-index: 99999999;\n  position: fixed;\n  width: 100%;\n  bottom: 0;\n}\n\n.lazy, .lazy * {\n  background: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Automatically generated, do not edit */
/* Automatically generated, do not edit */
.shared-button {
  text-decoration: none;
  padding: 3px 15px;
  /*&.btn-light {
    background-color: #fff;
    color: var(--v-primary);

    &:hover {
      color: #fff;
      background-color: \$primary;
    }
  }*/
}`, "",{"version":3,"sources":["webpack://./src/components/Button.vue"],"names":[],"mappings":"AAAA,yCAAyC;AACzC,yCAAyC;AACzC;EACE,qBAAqB;EACrB,iBAAiB;EACjB;;;;;;;;IAQE;AACJ","sourcesContent":["/* Automatically generated, do not edit */\n/* Automatically generated, do not edit */\n.shared-button {\n  text-decoration: none;\n  padding: 3px 15px;\n  /*&.btn-light {\n    background-color: #fff;\n    color: var(--v-primary);\n\n    &:hover {\n      color: #fff;\n      background-color: $primary;\n    }\n  }*/\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
